import React from "react"
import moment from "moment"
import {numberToCurrency} from "../../utils/currency"

const Preview = ({
  formik,
  interestRate,
  paymentDate
}) => {
  const {amount} = formik.values
  const length = 14
  const rangeElements = Array.from({length}, (_, i) => 1 + i)

  return(
    <>
      <div>Total amount: {numberToCurrency(amount)}</div>

      <div className="box mt-4">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th role="nr">Nr</th>
              <th role="paymentDate">Payment date</th>
              <th role="amount">Amount</th>
            </tr>
          </thead>
          <tbody>
            {
              rangeElements.map((value, index) => {
                const date = moment(paymentDate)
                const nextDate = date.add(index, 'M').format('MM/DD/YYYY')
                const monthlyAmount = parseInt(amount) * interestRate

                return(
                  <tr key={index}>
                    <td>{value}</td>
                    <td>{nextDate}</td>
                    <td>{numberToCurrency(monthlyAmount)} EURS</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Preview
