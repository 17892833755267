/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
import ToggleModal from "behaviours/toggleModal"
import Pusher from 'pusher-js'
import Rails from '@rails/ujs'
import bulmaCollapsible from '@creativebulma/bulma-collapsible';

window.Handlebars = require("handlebars")

Rails.start()

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener("DOMContentLoaded", () => {
  new ToggleModal()
});

// BULMA COLLAPSIBLE
document.addEventListener("DOMContentLoaded", () => {
  bulmaCollapsible.attach('.is-collapsible')
});

const bulmaCollapsibleElements = document.querySelectorAll('[data-action="collapse"]')
bulmaCollapsibleElements.forEach(bulmaCollapsibleElement => {
  const ref = bulmaCollapsibleElement.getAttribute('href')
  const element = document.querySelector(ref)
  if (element) {
    new bulmaCollapsible(element)
    element.bulmaCollapsible('expand')
  }
})