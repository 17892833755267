import React from "react"
import PropTypes from "prop-types"
import { Field, Control, Text, Box, Icon } from "bloomer"
import {CopyToClipboard} from 'react-copy-to-clipboard';

class EthereumAddress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ethWallet: props.ethWallet,
      copyTooltipText: "Copy to clipboard"
    }
  }

  render () {
    return (
      <React.Fragment>
        <Field>
          <Control>
            <div className='input-border'>
              <p style={{ float: "left", overflow: "hidden", textOverflow: "ellipsis" }}>{this.state.ethWallet}</p>
              <div className="dash-box__icon has-tooltip-arrow has-tooltip-multiline has-tooltip-text-centered" data-tooltip={this.state.copyTooltipText} onClick={this.onClick}>
                <CopyToClipboard text={this.state.ethWallet}
                  onCopy={() => this.setState({copyTooltipText: "Copied!"})}>
                  <svg role="icon" className="icon-svg--copy" >
                    <use href="#icons-copy"></use>
                  </svg>
                </CopyToClipboard>
              </div>
            </div>
          </Control>
        </Field>
      </React.Fragment>
    );
  }
}

export default EthereumAddress
