export default class ToggleModal {
  constructor() {
    const behaviours = document.querySelectorAll("[data-behaviours='ToggleModal']");
    Array.from(behaviours).forEach((el) => {
      this.handleClick(el);
    });
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleClick(element) {
    const targetId = element.dataset.target;
    element.addEventListener("click", (e) => {
      this.preventDefaults(e);
      document.querySelector(targetId).classList.toggle("is-active");
      let form = document.querySelector(targetId + " form");
      if (form) { form.reset(); }
    })
  }
}
