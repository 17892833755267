import React from "react"
import PropTypes from "prop-types"

class Modal extends React.Component {

  render () {
    const { active, children, closeModal } = this.props;

    const className = active ? 'modal is-active' : 'modal'
    return (
      <React.Fragment>
        <div className={className} >
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <div className="modal-card-body">
              <a className="delete is-large" aria-label="close" onClick={closeModal}></a>
              {children}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Modal.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.element,
  closeModal: PropTypes.func
};
export default Modal
