import React from "react"

const Amount = ({formik, balance}) => {
  return(
    <div className="simple_form">
      <div className="field">
        <label className="label">Total Amount:</label>
        <div className="control">
          <input
            className="input"
            placeholder="€"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
          />
        </div>
        <div className="help">
          Min.: 9.000 EURS / Max.: {balance} EURS
        </div>
        {formik.errors && formik.errors.step0 && formik.errors.step0.amount &&
         <p className="help is-danger">{formik.errors.step0.amount}</p>
        }
      </div>
    </div>
  )
}

export default Amount
