import React from "react"
import PropTypes from "prop-types"

class LoginOtp extends React.Component {
  constructor() {
    super();
    this.state = { checked: false }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    this.setState({
      checked: !this.state.checked
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="field">
          <label>
            <input 
              id="use_otp"
              type="checkbox" 
              checked={ this.state.checked } 
              onChange={ this.handleChange } />
            Use 2FA
          </label>
        </div>
        { this.state.checked && 
          <div className="field">
            <label className="label" htmlFor="user_otp_attempt">Otp code</label>
            <div className="control">
              <input className="input" type="password" name="user[otp_attempt]" id="user_otp_attempt" />
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

LoginOtp.propTypes = {
};
export default LoginOtp
