import React from "react"
import PropTypes from "prop-types"
import { LineChart, PieChart } from "react-chartkick"
import "chart.js"

class DashboardChart extends React.Component {
  constructor() {
    super();
  }

  render () {
    return (
      <React.Fragment>
        <LineChart data={this.props.data} />
      </React.Fragment>
    );
  }
}

DashboardChart.propTypes = {
  data: PropTypes.object
};
export default DashboardChart
