import React, {useState, useEffect} from "react"
import {useFormik} from 'formik'
import { Steps, Step } from "react-step-builder"
import Amount from './Amount'
import Preview from './Preview'
import Terms from './Terms'

const validateForm = (values, balance) => {
  const errors = {step0: {}, step2: {}}

  if (!values.amount || values.amount === '') {
    errors.step0["amount"] = "Can't be blank"
  } else if (isNaN(parseInt(values.amount))) {
    errors.step0["amount"] = "Must be a number"
  } else if (values.amount < 9000) {
    errors.step0["amount"] = "Must be greater or equal then 9.000 EURS"
  } else if (values.amount > parseFloat(balance)) {
    errors.step0["amount"] = "Operation not permitted"
  }

  if (!values.terms) {
    errors.step2["terms"] = "Must be accepted"
  }

  return errors
}

const Navigation = ({
  formik,
  current,
  size,
  prev,
  next
}) => {
  const hasError = () => {
    const errors = formik.errors || {}
    const stepErrors = formik.errors[`step${current - 1}`] || {}

    return Object.keys(stepErrors).length > 0
  }

  return(
    <div className="level my-4">
      <div className="level-left">
        {current !== 1 &&
         <div className="level-item">
           <a
             href="#"
             data-nav="previous"
             className="button is-primary is-rounded"
             onClick={prev}
           >
             Previous
           </a>
         </div>
        }
      </div>
      <div className="level-right">
        { !hasError() && current !== size &&
          <div className="level-item">
            <a
              href="#"
              data-nav="next"
              className="button is-primary is-rounded"
              onClick={next}
            >
              Next
            </a>
          </div>
        }

        { !hasError() && current === size &&
          <div className="level-item">
            <button
              className="button is-primary is-rounded"
              type="submit"
            >
              Create
            </button>
          </div>
        }
      </div>
    </div>
  )
}

const handleKeyDown = (event) => {
  if ((event.charCode || event.keyCode) === 13) {
    event.preventDefault()
  }
}

const CreateCryptobond = ({
  url,
  authenticityToken,
  balance,
  interestRate,
  paymentDate
}) => {
  const [attached, setAttached] = useState(false)
  const pages = document.getElementsByClassName("step-item").length - 1

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      amount: "",
      terms: false
    },
    validate: (values) => validateForm(values, balance)
  })

  const stepperConfig = {
    navigation: {
      component: (props) => (<Navigation {...props} formik={formik} />),
      location: "after"
    }
  }

  return(
    <form action={url} method="post" onKeyDown={handleKeyDown}>
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken}
      />
      <input
        type="hidden"
        name="amount"
        value={formik.values.amount}
      />
      <Steps config={stepperConfig}>
        <Step
          component={Amount}
          formik={formik}
          balance={balance}
        />
        <Step
          component={Preview}
          formik={formik}
          interestRate={interestRate}
          paymentDate={paymentDate}
        />
        <Step
          component={Terms}
          formik={formik}
        />
      </Steps>
    </form>
  )
}

export default CreateCryptobond
