import React from "react"
import PropTypes from "prop-types"
import { Field, Control, Select, Modal, ModalBackground, ModalContent, ModalClose } from "bloomer"

class DepositAddressesShow extends React.Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      selected: ""
    }
  }

  onChange = (selected) => {
    const selectedOption = selected.target.value
    this.setState({
      isActive: true,
      selected: selectedOption
    })
  }

  onClick = () => {
    this.setState({
      isActive: false,
      selected: ""
    })
  }

  render () {
    const addresses = this.props.addresses

    return (
      <React.Fragment>
        <Field>
          <Control>
            <Select name="address" onChange={this.onChange} value={this.state.selected}>
              <option key={null} value="">Select address</option>
            { this.props.addresses.map((address) =>
              <option key={address.index} value={address.label}>{address.label}</option>
            )}
            </Select>
          </Control>
        </Field>
        { this.state.selected && this.state.isActive &&
          <Modal isActive={this.state.isActive}>
            <ModalBackground />
            <ModalContent>
              <div className="modal-address">
                <div className="menu">
                  <p className="menu-label">QRCode Address</p>
                  <div className="qrcode">
                    <div dangerouslySetInnerHTML={{__html: this.props.addresses.find( address => address.label === this.state.selected).qr}} />
                  </div>
                  <p className="menu-label">Address code</p>
                  <ul className="menu-list">
                    <li>{this.state.selected}</li>
                  </ul>
                </div>
                <p className="hint--large">Have you questions? Read our <a target="_blank" href="https://savebox.freshdesk.com/support/solutions/folders/44001190868" className="is-link">FAQ</a></p>
              </div>
            </ModalContent>
            <ModalClose onClick={this.onClick} isSize="large"/>
          </Modal>
        }
      </React.Fragment>
    );
  }
}

DepositAddressesShow.propTypes = {
  addresses: PropTypes.array
};
export default DepositAddressesShow
