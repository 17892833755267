import React from "react"
import {numberToCurrency} from "../../utils/currency"

const Terms = ({formik}) => {
  const {amount} = formik.values

  return(
    <>
      <div>Total amount: {numberToCurrency(amount)}</div>
      <div className="box my-4">
        <b>AGGREEMENT TO TERMS</b>
        <p>
          The Subscriber explicitly accepts the Terms and Conditions
          relating to the subscription of the CryptoBond available
          here. The Subscriber confirms that He/She/It has received
          all the available documentation relating to the subscription
          of the CryptoBond including all the offering papers and the
          subscription contract.
        </p>
      </div>
      <div className="form">
        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input
                className="boolean"
                name="terms"
                type="checkbox"
                value={formik.values.terms}
                checked={formik.values.terms}
                onChange={formik.handleChange}
              />
              I read and accepted Terms and conditions displayed above
            </label>
          </div>
          {formik.errors && formik.errors.step2 && formik.errors.step2.terms &&
           <p className="help is-danger">{formik.errors.step2.terms}</p>
          }
        </div>
      </div>
    </>
  )
}

export default Terms
